<template>
  <PageWithLayout
    :isBackBtn="true"
    :isBackWebEnd="true"
    :bottomPadding="104">
    <!-- top -->
    <div class="section_comm section_top">
      <h1 class="ir_pm area_img">오늘은 투자왕</h1>
      <div class="inner_top_content">
        <span class="ir_pm img_super">super</span>
        <span class="txt_category">투자</span>
        <h2 class="tit_map">용돈으로 시작하는 똑똑한 주식 투자</h2>
        <div class="area_price">
          <p class="desc_info">오늘은 투자왕 포함 총 12개 학습맵</p>
          <p class="desc_delete_price">96,000원</p>
          <p class="desc_discount_price">
            <span class="txt_percent">50%</span>
            <span class="txt_price">48,000원</span>
          </p>
          <p class="desc_info">1개 학습 단독 구매시 8,000원</p>
        </div>
        <div class="list_info">
          <dl>
            <dt>권장나이</dt>
            <dd>10세 +</dd>
          </dl>
          <dl>
            <dt>난이도</dt>
            <dd>
              <div class="group_icon_info">
                <span class="icon_info icon_star fill"></span>
                <span class="icon_info icon_star fill"></span>
                <span class="icon_info icon_star"></span>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>교과연계</dt>
            <dd>
              <div class="group_icon_info">
                <span class="icon_info icon_pen fill"></span>
                <span class="icon_info icon_pen"></span>
                <span class="icon_info icon_pen"></span>
              </div>
            </dd>
          </dl>
        </div>
        <p class="desc_map">주식투자에 관심이 많은 아이들이 투기와 투자의 차이를 알고, 기업에 대해 이해하고, 주식투자의 기초를 알 수 있는 퀴즈로 구성되어 있어요.</p>
      </div>
    </div>
    <!-- allinone -->
    <div class="section_comm section_allinone">
      <h3 class="tit_section">미래세대 필수 역량<br>ALL IN ONE 패키지</h3>
      <div class="box_map_name">
        <div class="inner_map_name">
            <div class="area_left">
            <span class="txt_order">1st</span>
            <span class="txt_map_name">용돈으로 시작하는 똑똑한 주식 투자</span>
          </div>
          <div class="area_right">
            <span class="txt_new">NE₩</span>
          </div>
        </div>
        <div class="shadow_box_map shadow_box_map1"></div>
        <div class="shadow_box_map shadow_box_map2"></div>
      </div>
      <div class="group_dot">
        <div class="item_dot"></div>
        <div class="item_dot"></div>
        <div class="item_dot"></div>
      </div>
      <div class="group_catagory">
        <div
          v-for="index of 12"
          :key="`categoryItem${index+1}`"
          class="item_category">
          <div
            class="icon_category"
            :style="{'background-image':`url(/assets/images/world_update/icon_category_${viewModel.categoryDataList[index-1].id}.png)`}"></div>
          <span class="txt_category">{{ viewModel.categoryDataList[index-1].text }}</span>
        </div>
      </div>
      <div class="item_map_info">
        <strong class="tit_map_info">매월 새로운 학습맵 공개</strong>
        <p class="desc_map_info">경제금융·시사상식·인문사회·교과연계 필수 역량을 골고루 키워주는 커리큘럼</p>
        <ul class="list_map_info">
          <li>총 12개 학습맵 (약 12개월 소요)</li>
          <li>매월 150개 실전형 퀴즈 제공</li>
        </ul>
      </div>
      <div class="item_map_info">
        <strong class="tit_map_info">이런 것을 배워요<br>10대 맞춤 교과연계 학습 포인트</strong>
        <div class="group_curriculum">
          <div class="box_curriculum">용돈으로 시작하는 똑똑한 <span class="tc_primary200">주식투자</span></div>
          <div class="box_curriculum">경제금융 관련 필수 <span class="tc_primary200">영단어</span></div>
          <div class="box_curriculum">돈과 관련된 <span class="tc_primary200">속담</span></div>
          <div class="box_curriculum">돈과 관련된 <span class="tc_primary200">사자성어</span></div>
          <div class="box_curriculum">K-Pop <span class="tc_primary200">문화 속 경제</span> 이야기</div>
          <div class="box_curriculum"><span class="tc_primary200">금융사기</span> 피하는 방법</div>
          <div class="box_curriculum"><span class="tc_primary200">세계 역사</span>와 경제</div>
          <div class="box_curriculum"><span class="tc_primary200">한국 역사</span>와 경제</div>
          <div class="box_curriculum"><span class="tc_primary200">예술과 경제</span>의 관계</div>
          <div class="box_curriculum"><span class="tc_primary200">스포츠</span>와 뗄 수 없는 경제 이야기</div>
          <div class="box_curriculum">뉴스로 알아보는 <span class="tc_primary200">시사 상식</span></div>
          <div class="box_curriculum">세계 경제를 움직이는 <span class="tc_primary200">사람들</span></div>
        </div>
      </div>
    </div>
    <!-- quiz -->
    <div class="section_comm section_quiz">
      <h3 class="tit_section">만족도 <span class="tc_primary">98%</span><br>퍼핀월드 경제금융 퀴즈</h3>
      <div class="group_review">
        <div class="box_review box_review_left">
          <div class="inner_box_review">
            <span class="icon_emoji icon_emoji1"></span>
            <p class="desc_review">상황에 <span class="tc_primary">몰입해서</span> 배우게 돼요</p>
          </div>
        </div>
        <div class="box_review box_review_right">
          <div class="inner_box_review">
            <span class="icon_emoji icon_emoji2"></span>
            <p class="desc_review"><span class="tc_primary">쉽게 설명</span>해줘서 좋아요</p>
          </div>
        </div>
        <div class="box_review box_review_left">
          <div class="inner_box_review">
            <span class="icon_emoji icon_emoji3"></span>
            <p class="desc_review">더 <span class="tc_primary">다양한 주제</span>도 만들어 주세요!</p>
          </div>
        </div>
      </div>
      <p class="desc_quiz">"다양한 주제의 퀴즈도 풀고 싶어요"<br>꾸준히 쏟아졌던 아이들의 요청을 반영한</p>
      <p class="desc_quiz desc_quiz_bold">ALL IN ONE 패키지가 탄생했습니다.</p>
    </div>
    <!-- content -->
    <div class="section_comm section_content">
      <h3 class="tit_section">부모님도 만족하는<br>퍼핀월드 학습 콘텐츠</h3>
      <p class="desc_content">억지로 시키지 않아도</p>
      <p class="desc_content desc_content_bold">아이가 <span class="txt_point">알</span><span class="txt_point">아</span><span class="txt_point">서</span> 푸는 이유?</p>
      <p class="desc_content">공감되는 상황, 쉽고 재밌는 설명의 퍼핀만의 학습 콘텐츠가 아이와 부모님 모두를 만족시킵니다<span class="icon_heart"></span></p>
      <div class="area_img"></div>
    </div>
    <div class="bar_text bar_text_black"></div>
    <!-- premium -->
    <div class="section_comm section_premium">
      <div class="area_img">
        <div class="inner_img"></div>
      </div>
      <h3 class="tit_section">용돈으로 시작하는<br>똑똑한 주식투자</h3>
      <ol class="list_premium">
        <li>총 30일 코스 | 150개 퀴즈</li>
        <li>올바른 투자 마인드 세팅부터 실전 연습까지</li>
        <li>10대 맞춤형 사례 중심 투자 교육</li>
      </ol>
    </div>
    <!-- investment -->
    <div class="section_comm section_investment">
      <h3 class="tit_section">지금 배운 내용이<br>아이의 평생을 좌우한다</h3>
      <p class="desc_investment"><u>투기와 투자를 구분</u>하지 못하고<br>소문에만 의존하는 투자자는 NO!</p>
      <p class="desc_investment">우리 아이는 <u>정보와 데이터를 분석</u>할 줄 아는<br>똑똑한 투자자로 키워주세요!</p>
      <div class="group_circle">
        <div class="item_circle">
          <p class="desc_circle">투자 마인드</p>
        </div>
        <div class="item_circle">
          <p class="desc_circle">투자 지식</p>
        </div>
        <div class="item_circle">
          <p class="desc_circle">실전 연습</p>
        </div>
      </div>
    </div>
    <div class="bar_text bar_text_primary"></div>
    <!-- course -->
    <div class="section_comm section_course">
      <div class="group_course">
        <div class="box_course">
          <span class="txt_course_num">Course 1~2</span>
          <strong class="tit_course">주식투자 첫 걸음, 기업분석은 필수!</strong>
        </div>
        <div class="box_course">
          <p class="desc_sub_tit">글로벌 기업의 주인이 되자 </p>
          <p class="desc_course">애플, 나이키 등 글로벌 경제를 이끄는 주요 기업에 대해 알아보아요.</p>
        </div>
        <div class="box_course">
          <p class="desc_sub_tit">K산업을 이끄는 기업의 주인이 되자</p>
          <p class="desc_course">삼성전자, 현대 등 국내 경제를 이끄는 주요 기업에 대해 알아보아요.</p>
        </div>
      </div>
      <div class="group_course">
        <div class="box_course">
          <span class="txt_course_num">Course 3~5</span>
          <strong class="tit_course">주식을 사고파는 방법 A to Z</strong>
        </div>
        <div class="box_course">
          <p class="desc_sub_tit">상장기업의 주인이 되자</p>
          <p class="desc_course">국내 및 해외 증권시장에 상장한 기업에 투자하는 법</p>
        </div>
        <div class="box_course">
          <p class="desc_sub_tit">숨은 보석, 비상장기업의 주인이 되자</p>
          <p class="desc_course">증권시장에 상장하지 않은 기업에 투자하는 법</p>
        </div>
        <div class="box_course">
          <p class="desc_sub_tit">차트, 기업 실적 제대로 읽자</p>
          <p class="desc_course">기업 분석의 토대가 되는 자료를 해석하는 법</p>
        </div>
      </div>
      <div class="group_course">
        <div class="box_course">
          <span class="txt_course_num">Course 6~9</span>
          <strong class="tit_course">주식투자 실전! 모의체험</strong>
        </div>
        <div class="box_course">
          <p class="desc_sub_tit">
            <span class="badge_course">실전 1</span>
          </p>
          <p class="desc_course">투기가 아닌 투자를 하고 싶다면 가져야 할 마음</p>
        </div>
        <div class="box_course">
          <p class="desc_sub_tit">
            <span class="badge_course">실전 2</span>
          </p>
          <p class="desc_course">내가 투자하는 이유는? 목표수익과 기간 정하는 법</p>
        </div>
        <div class="box_course">
          <p class="desc_sub_tit">
            <span class="badge_course">실전 3</span>
          </p>
          <p class="desc_course">포트폴리오 구성하고 실제 주식 사고파는 법</p>
        </div>
        <div class="box_course">
          <p class="desc_sub_tit">
            <span class="badge_course">실전 4</span>
          </p>
          <p class="desc_course">주가 상승, 하락, 상장폐지 다양한 상황 체험하기</p>
        </div>
      </div>
    </div>
    <!-- faq -->
    <div class="section_comm section_faq">
      <h3 class="tit_section">자주 묻는 질문</h3>
      <div class="item_faq">
        <p class="desc_question"><span class="badge_question">Q</span>패키지 구매 시 모든 학습맵을 볼 수 있나요?</p>
        <p class="desc_answer"><span class="badge_answer">A</span>패키지 구매 시 매월 1개의 학습맵이 순차적으로 공개 됩니다.</p>
      </div>
      <div class="item_faq">
        <p class="desc_question"><span class="badge_question">Q</span>하나의 학습맵에는 몇 개의 퀴즈가 포함되나요?</p>
        <p class="desc_answer"><span class="badge_answer">A</span>각 학습맵에는 총150개의 퀴즈가 포함되며 매일 5개씩, 30일동안 풀 수 있습니다.</p>
      </div>
      <div class="item_faq">
        <p class="desc_question"><span class="badge_question">Q</span>구매한 학습맵은 얼마동안 이용할 수 있나요?</p>
        <p class="desc_answer"><span class="badge_answer">A</span>각 맵이 처음 공개된 날로부터 1년동안 이용할 수 있습니다.</p>
      </div>
      <div class="item_faq">
        <p class="desc_question"><span class="badge_question">Q</span>자녀가 둘인데, 학습맵을 각각 구매해야하나요?</p>
        <p class="desc_answer"><span class="badge_answer">A</span>가족대표께서 한 번만 구매하면, 내 가족에 속한 자녀 모두 이용할 수 있습니다.</p>
      </div>
    </div>
    <!-- foot -->
    <div class="foot_lemontree foot_lemontree_fiexed">
      <div class="inner_foot">
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="지도 열어주기"
          @onClickBtn="viewModel.onClickPaymentPopup()" />
      </div>
    </div>
    <!-- popup -->
    <template v-slot:popup>
      <Layer
        v-if="viewModel.isPaymentPopup"
        :isDragable="true"
        :isCloseBtn="false"
        @onClickClose="viewModel.onClickPaymentPopupClose()">
        <button
          v-for="(paymentItem, index) in viewModel.paymentDataList"
          :key="`paymentItem${index+1}`"
          :class="[
            'btn_payment_item',
            { 'active': viewModel.selectMapId === paymentItem.mapId }
          ]"
          @click="viewModel.onClickPaymentItem(paymentItem.mapId)">
          <div class="head_payment">
            <span v-if="paymentItem.isHot" class="badge_hot">HOT</span>
            <strong class="tit_payment" v-html="paymentItem.title"></strong>
          </div>
          <div class="body_payment">
            <ul
              v-if="paymentItem.info.length > 1"
              class="list_payment_info">
              <li
                v-for="(infoItem, index) in paymentItem.info"
                :key="`infoItem${index+1}`"
                v-html="infoItem"></li>
            </ul>
            <p v-else
              class="desc_payment_info"
              v-html="paymentItem.info[0]"></p>
          </div>
          <div class="foot_payment">
            <div class="area_price">
              <p
                v-if="paymentItem.deletePrice"
                class="desc_delete_price">{{ paymentItem.deletePrice }}</p>
              <p
                v-if="paymentItem.discountPercent"
                class="desc_discount_price">
                <span class="txt_percent">{{ paymentItem.discountPercent }}</span>
                <span class="txt_price">{{ paymentItem.price }}</span>
              </p>
              <span v-else class="txt_price">{{ paymentItem.price }}</span>
            </div>
          </div>
        </button>
        <Button
          btnSize="medium"
          btnStyle="primary"
          :text="viewModel.paymentDataList.find(item => item.mapId === viewModel.selectMapId).buttonText"
          @onClickBtn="viewModel.onClickPayment()" />
      </Layer>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import Layer from '@/components/layout/popup/Layer';
import Button from '@/components/common/button/Button';
// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';
// viewModel
import WorldUpdateVIewModel from '@/views/worldUpdate/viewModel/WorldUpdateVIewModel'

export default {
  name: 'WorldUpdateIntroduce',
  mixins:[ InterFaceMixin, BackKeyEventMixin ],
  components:{
    PageWithLayout,
    Layer,
    Button,
  },
  mounted(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new WorldUpdateVIewModel(),
    }
  },
  methods:{
    onClickBackKey(){
      this.webEnd();
    },
  }
}
</script>

<style scoped>
/* common */
.section_comm{word-wrap:break-word;word-break:keep-all}

/* common - price */
.area_price .desc_delete_price{font-weight:500;font-size:14px;line-height:24px;color:#999;text-decoration:line-through;}
.area_price .txt_percent,
.area_price .txt_price{display:inline-block;font-weight:700;font-size:20px;line-height:28px;vertical-align:top}
.area_price .txt_percent{color:#F98E5A}
.area_price .txt_price{color:#111}
.area_price .txt_percent + .txt_price{margin-left:8px}

/* common - section */
.tit_section{font-weight:700;font-size:24px;line-height:36px;color:#111}

/* bar_text */
.bar_text{height:54px;background-repeat:repeat-x;background-position:10px 0;background-size:211px 54px}
.bar_text.bar_text_black{background-image:url(/assets/images/world_update/bar_text_black.png);animation:moveBg 5s linear infinite}
.bar_text.bar_text_primary{background-image:url(/assets/images/world_update/bar_text_primary.png);animation:moveBgReverse 5s linear infinite}

@keyframes moveBg{
  0% {background-position:10px 0}
  100% {background-position:-201px 0}
}
@keyframes moveBgReverse{
  0% {background-position:-201px 0}
  100% {background-position:10px 0}
}

/* top */
.section_top .inner_top_content{padding:0 20px 24px}
.section_top .img_super,
.section_top .txt_category,
.section_top .area_price,
.section_top .area_price .desc_discount_price + .desc_info,
.section_top .list_info,
.section_top .desc_map{margin-top:24px}
.section_top .area_img{padding-top:88.89%;background:url(/assets/images/world_update/img_top.png)no-repeat 0 0;background-size:contain}
.section_top .img_super{width:74px;height:28px;background:url(/assets/images/world_update/img_top_super.png)no-repeat 0 0;background-size:contain}
.section_top .txt_category{display:block;font-weight:500;font-size:14px;line-height:22px;color:#111}
.section_top .tit_map{display:block;font-weight:700;font-size:20px;line-height:28px;color:#111}
.section_top .area_price .desc_delete_price{line-height:22px;text-decoration:none}
.section_top .area_price .desc_info + .desc_delete_price{margin-top:4px}
.section_top .area_price .desc_info{font-weight:500;font-size:14px;line-height:22px;color:#333}
.section_top .list_info{display:flex}
.section_top .list_info dl{flex:0 1 33.33%;padding:8px 0;text-align:center}
.section_top .list_info dl dt{font-weight:500;font-size:14px;line-height:22px;color:#333}
.section_top .list_info dl dd{font-weight:700;font-size:18px;line-height:28px;color:#8F69DD}
.section_top .list_info dl dd .group_icon_info{margin-top:5px}
.section_top .list_info dl dd .group_icon_info .icon_info{display:inline-block;width:22px;height:22px;vertical-align:top}
.section_top .list_info dl dd .group_icon_info .icon_info + .icon_info{margin-left:4px}
.section_top .list_info dl dd .group_icon_info .icon_star.fill{background:url(/assets/images/world_update/icon_star_fill.png) no-repeat 0 0;background-size:contain}
.section_top .list_info dl dd .group_icon_info .icon_star{background:url(/assets/images/world_update/icon_star.png) no-repeat 0 0;background-size:contain}
.section_top .list_info dl dd .group_icon_info .icon_pen.fill{background:url(/assets/images/world_update/icon_pen_fill.png) no-repeat 0 0;background-size:contain}
.section_top .list_info dl dd .group_icon_info .icon_pen{background:url(/assets/images/world_update/icon_pen.png) no-repeat 0 0;background-size:contain}
.section_top .list_info dl + dl{border-left:1px solid #F3F1F1}
.section_top .desc_map{font-weight:500;font-size:16px;line-height:24px;color:#666}

/* allinone */
.section_allinone{padding:40px 20px;background-color:#232323}
.section_allinone .tit_section{color:#fff}
.box_map_name{position:relative;margin-top:16px;}
.box_map_name .txt_order,
.box_map_name .txt_new{display:inline-block;font-weight:700;font-size:16px;line-height:24px;color:#111;vertical-align:top}
.box_map_name .txt_map_name{display:inline-block;font-weight:700;font-size:14px;line-height:24px;color:#111;vertical-align:top}
.box_map_name .txt_order{margin-right:8px}
.box_map_name .txt_new{color:#fff}
.box_map_name .inner_map_name{position:relative;z-index:4;overflow:hidden;padding:16px 12px;background-color:#BEA9ED}
.box_map_name .shadow_box_map{position:absolute;height:100%}
.box_map_name .shadow_box_map1{z-index:3;top:16px;left:10px;right:10px;background-color:#000}
.box_map_name .shadow_box_map2{z-index:2;top:32px;left:20px;right:20px;background-color:#181818}
.group_dot{margin-top:40px}
.group_dot .item_dot{width:6px;height:6px;margin:0 auto;border-radius:100%;background-color:#000}
.group_dot .item_dot + .item_dot{margin-top:8px}
.group_catagory{display:flex;flex-wrap:wrap;margin:8px -2px -2px}
.group_catagory .item_category{flex:1 0 30%;margin:2px;padding:12px;box-sizing:border-box;text-align:center;background-color:#000}
.group_catagory .icon_category{display:inline-block;width:32px;height:32px;background-repeat:no-repeat;background-size:contain;vertical-align:top}
.group_catagory .txt_category{display:block;margin-top:10px;font-weight:700;font-size:16px;line-height:24px;color:#fff}
.item_map_info{margin-top:48px}
.item_map_info .list_map_info,
.item_map_info .desc_map_info,
.item_map_info .group_curriculum{margin-top:16px}
.item_map_info .tit_map_info{display:block;font-weight:700;font-size:20px;line-height:28px;color:#BEA9ED}
.item_map_info .list_map_info li,
.item_map_info .desc_map_info{font-weight:500;font-size:14px;line-height:22px;color:#fff}
.item_map_info .list_map_info li{padding-left:15px;text-indent:-15px}
.item_map_info .list_map_info li:before{display:inline-block;width:3px;height:3px;margin:10px 6px 9px;border-radius:100%;background-color:#fff;vertical-align:top;content:''}
.item_map_info .group_curriculum .box_curriculum{padding:16px;font-weight:700;font-size:16px;line-height:24px;background-color:#000;color:#fff;text-align:center}
.item_map_info .group_curriculum .box_curriculum + .box_curriculum{margin-top:4px}

/* quiz */
.section_quiz{padding:40px 20px;background-color:#F1EEE1}
.group_review{margin-top:24px}
.group_review:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.group_review .box_review .inner_box_review{display:inline-block;overflow:hidden;padding:14px 16px 14px 14px;border:1px solid #232323;background:#FFF;box-shadow:4px 4px 0px 0px #232323;vertical-align:top}
.group_review .box_review_left{text-align:left}
.group_review .box_review_left .icon_emoji{float:left;margin-right:10px}
.group_review .box_review_right{text-align:right}
.group_review .box_review_right .icon_emoji{float:right;margin-left:10px}
.group_review .box_review .desc_review{overflow:hidden}
.group_review .box_review .icon_emoji1{background-image:url(/assets/images/world_update/icon_emoji1.png)}
.group_review .box_review .icon_emoji2{background-image:url(/assets/images/world_update/icon_emoji2.png)}
.group_review .box_review .icon_emoji3{background-image:url(/assets/images/world_update/icon_emoji3.png)}
.group_review .box_review .icon_emoji{width:24px;height:24px;background-size:contain}
.group_review .box_review + .box_review{margin-top:16px}
.group_review + .desc_quiz{margin-top:24px}
.desc_quiz + .desc_quiz{margin-top:12px}
.desc_quiz{font-weight:500;font-size:14px;line-height:22px;color:#111}
.desc_quiz.desc_quiz_bold{font-weight:700;font-size:16px;line-height:24px}

/* content */
.section_content{padding:40px 20px}
.section_content .tit_section + .desc_content{margin-top:24px}
.section_content .desc_content_bold + .desc_content{margin-top:10px}
.section_content .desc_content + .desc_content_bold{margin-top:8px}
.section_content .desc_content{font-weight:500;font-size:14px;line-height:22px;color:#111}
.section_content .desc_content_bold{font-weight:700;font-size:16px;line-height:24px}
.section_content .txt_point{position:relative}
.section_content .txt_point:after{position:absolute;top:-4px;left:50%;width:4px;height:4px;margin-left:-4px;border-radius:100%;background-color:#BEA9ED;content:''}
.section_content .icon_heart{display:inline-block;width:19px;height:14px;margin:4px 0 0 3px;background:url(/assets/images/world_update/icon_heart.png)no-repeat 0 0;background-size:contain;vertical-align:top}
.section_content .area_img{margin-top:24px;padding-top:67.19%;background:url(/assets/images/world_update/img_content.jpg)no-repeat 0 0;background-size:contain}

/* premium */
.section_premium{padding:40px 20px;background-color:#BEA9ED}
.section_premium .area_img{max-width:320px;margin:0 auto}
.section_premium .area_img .inner_img{padding-top:115.626%;background:url(/assets/images/world_update/img_premium.png)no-repeat 0 0;background-size:contain}
.section_premium .tit_section{margin-top:24px;text-align:center}
.section_premium .list_premium{margin-top:24px;padding-top:24px;border-top:2px dashed #232323;counter-reset:li}
.section_premium .list_premium li{padding-left:32px;text-indent:-32px;font-weight:500;font-size:14px;line-height:22px;color:#111}
.section_premium .list_premium li:before{display:inline-block;width:20px;height:20px;margin:1px 12px 0 0;padding-top:2px;border-radius:100%;font-weight:600;font-size:12px;line-height:16px;background-color:#232323;color:#fff;vertical-align:top;text-align:center;content:counter(li);counter-increment: li;box-sizing:border-box;text-indent:0}
.section_premium .list_premium li + li{margin-top:8px}

/* investment */
.section_investment{padding:40px 20px;background:url(/assets/images/world_update/bg_investment.jpg)no-repeat center;background-size:cover}
.section_investment .tit_section{color:#fff}
.section_investment .desc_investment{font-weight:500;font-size:14px;line-height:22px;color:#fff}
.section_investment .group_circle .desc_circle{font-weight:500;font-size:16px;line-height:24px;color:#fff;letter-spacing:-0.02em}
.section_investment .tit_section + .desc_investment,
.section_investment .desc_investment + .desc_investment,
.section_investment .desc_investment + .group_circle{margin-top:24px}
.section_investment .group_circle{max-width:320px}
.section_investment .group_circle .item_circle{display:inline-block;position:relative;width:37%;padding-top:37%;border:1px solid #fff;border-radius:100%;vertical-align:top}
.section_investment .group_circle .item_circle + .item_circle{margin-left:-7%}
.section_investment .group_circle .item_circle .desc_circle{position:absolute;left:50%;top:50%;width:100%;max-width:170px;padding:0 17%;transform:translate(-50%, -50%);box-sizing:border-box;text-align:center;box-sizing:border-box}

/* course */
.section_course{padding:40px 20px;background-color:#000}
.section_course .box_course{padding:20px;background-color:#232323}
.section_course .box_course .txt_course_num + .tit_course,
.section_course .box_course .desc_sub_tit + .desc_course{margin-top:8px}
.section_course .box_course .txt_course_num{display:block;font-weight:600;font-size:14px;line-height:22px;color:#BEA9ED}
.section_course .box_course .tit_course{display:block;font-weight:700;font-size:18px;line-height:28px;color:#fff}
.section_course .box_course .desc_sub_tit{font-weight:700;font-size:16px;line-height:24px;color:#fff}
.section_course .box_course .desc_course{font-weight:500;font-size:14px;line-height:22px;color:#fff}
.section_course .box_course .badge_course{margin:1px 8px 0 0;padding:2px 8px;border-radius:2px;font-weight:600;font-size:12px;line-height:16px;background-color:#BEA9ED;color:#111}
.section_course .group_course + .group_course{margin-top:24px}
.section_course .box_course + .box_course{margin-top:2px}

/* faq */
.section_faq{padding:40px 20px}
.section_faq .item_faq{margin-top:24px}
.section_faq .badge_question,
.section_faq .badge_answer{display:inline-block;width:24px;height:24px;border-radius:100%;margin-right:12px;padding-top:4px;font-weight:600;font-size:12px;line-height:16px;text-align:center;vertical-align:top;box-sizing:border-box;text-indent:0}
.section_faq .badge_question{background-color:#BEA9ED;color:#111}
.section_faq .badge_answer{background-color:#232323;color:#fff}
.section_faq .desc_question,
.section_faq .desc_answer{padding-left:36px;font-size:14px;line-height:22px;text-indent:-36px}
.section_faq .desc_question{font-weight:600;color:#111}
.section_faq .desc_answer{margin-top:12px;font-weight:500;color:#666}

/* foot */
.foot_lemontree .inner_foot{padding:16px 20px 32px;background-color:#fff}

/* layer */
.btn_payment_item{display:block;width:100%;padding:16px;border:1px solid #E7E5E5;border-radius:8px;text-align:left;box-sizing:border-box}
.btn_payment_item + .btn_payment_item{margin-top:16px}
.btn_payment_item + .btn_primary{margin-top:24px}
.btn_payment_item .head_payment{overflow:hidden}
.btn_payment_item .head_payment .badge_hot{float:right;padding:4px 8px;border-radius:100px;font-weight:600;font-size:12px;line-height:16px;background-color:#F0EAFA;color:#8F69DD}
.btn_payment_item .head_payment .tit_payment{overflow:hidden;font-weight:700;font-size:16px;line-height:24px;color:#111}
.btn_payment_item .body_payment{margin-top:8px}
.btn_payment_item .body_payment .list_payment_info li,
.btn_payment_item .body_payment .desc_payment_info{font-weight:500;font-size:14px;line-height:22px;color:#666}
.btn_payment_item .body_payment .list_payment_info li{padding-left:15px;text-indent:-15px}
.btn_payment_item .body_payment .list_payment_info li:before{display:inline-block;width:3px;height:3px;margin:10px 6px 9px;border-radius:100%;background-color:#666;vertical-align:top;content:''}
.btn_payment_item .foot_payment{margin-top:8px;padding-top:8px;border-top:1px solid #F3F1F1;text-align:right}
.btn_payment_item.active{padding:15px;border:2px solid #BEA9ED;box-shadow: 0px 8px 8px 0px rgba(190, 169, 237, 0.24);}
</style>