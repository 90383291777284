import { GET_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@/store/index';
import router from '@/router';

export default class WorldUpdateVIewModel {
  constructor() {
    this.categoryDataList = [
      {
        id:'investment',
        text: '투자'
      },
      {
        id:'current_event',
        text: '시사'
      },
      {
        id:'english',
        text: '영어'
      },
      {
        id:'proverb',
        text: '속담'
      },
      {
        id:'idiom',
        text: '사자성어'
      },
      {
        id:'culture',
        text: '문화'
      },
      {
        id:'art',
        text: '예술'
      },
      {
        id:'knowledge',
        text: '상식'
      },
      {
        id:'character',
        text: '인물'
      },
      {
        id:'world_history',
        text: '세계사'
      },
      {
        id:'korean_history',
        text: '한국사'
      },
      {
        id:'sport',
        text: '스포츠'
      },
    ]
    this.isPaymentPopup = false;
    this.paymentDataList = [
      {
        mapId: '5-1',
        isHot: true,
        title: `퍼핀 프리미엄<br>미래역량 ALL IN ONE 패키지`,
        info: [
          `총 12개월 학습 / 총 1,800개 퀴즈`,
          `매달 1개 학습맵 / 150개 퀴즈 순차 공개`,
        ],
        deletePrice: '96,000원',
        discountPercent:'50%',
        price: '48,000원',
        buttonText:'혜택가 48,000원에 구매하기'
      },
      {
        mapId: '5-2',
        isHot: false,
        title: `10대에 시작하는 똑똑한 주식투자`,
        info: [
          `1개 학습맵 단독 구매`,
        ],
        deletePrice: '',
        discountPercent:'',
        price: '8,000원',
        buttonText:'8,000원에 구매하기'
      }
    ];
    this.funnelType = 'content1';
    this.selectMapId = '5-1';
    this.defaultSelectMapId = '5-1';
  }
  init(){
    this.getWorldmapUpdateInit();
  }
  onClickPaymentPopup(){
    this.isPaymentPopup = true;
    this.getWorldmapUpdateBottomSheet();
  }
  onClickPaymentPopupClose(){
    this.isPaymentPopup = false;
    this.selectMapId = this.defaultSelectMapId;
  }
  onClickPaymentItem(mapId){
    this.selectMapId = mapId;
  }
  onClickPayment(){
    this.getWorldmapUpdateBuy()
  }
  getWorldmapUpdateInit(){
    const path = `${apiPath.WORLDMAP_UPDATE_FUNNEL.format(this.funnelType,3)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  getWorldmapUpdateBottomSheet(){
    const path = `${apiPath.WORLDMAP_UPDATE_FUNNEL.format(this.funnelType,4)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  getWorldmapUpdateBuy(){
    const path = `${apiPath.WORLDMAP_UPDATE_FUNNEL.format(this.funnelType,this.selectMapId)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      this.isPaymentPopup = false;
      store.dispatch('commonAlert/fetchAlertStart', {
        alertMsg: `<strong>구매예약되었어요.<br>공개되면 알려드릴게요.</strong>프리미엄 유료 콘텐츠가 하반기 공개 준비중이에요. 출시되면 알려드릴게요.`,
        compeleteBtnText: '알겠어요',
        compeleteBtnStyle:'secondary',
      });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}